import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled, { css } from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
var buildSizeImageQuerie = function buildSizeImageQuerie(image, position, size) {
  var sizes = (image == null ? void 0 : image.sizes) || [];
  var value = sizes[position];
  if (!value) return '';
  if (size) {
    return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      ", " {\n        height: ", "%;\n        width: ", "%;\n      }\n    "])), getMediaQuery(size), value, value);
  }
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    height: ", "%;\n    width: ", "%;\n  "])), value, value);
};
export var Container = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  * img {\n    border-radius: ", ";\n    ", "\n    ", "\n    ", "\n    ", "\n  }\n\n  * a {\n    text-decoration: ", ";\n  }\n"])), themeGet('radii.2'), function (_ref) {
  var image = _ref.image;
  return buildSizeImageQuerie(image, 0);
}, function (_ref2) {
  var image = _ref2.image;
  return buildSizeImageQuerie(image, 1, 'small');
}, function (_ref3) {
  var image = _ref3.image;
  return buildSizeImageQuerie(image, 2, 'medium');
}, function (_ref4) {
  var image = _ref4.image;
  return buildSizeImageQuerie(image, 3, 'large');
}, function (_ref5) {
  var linksUnderline = _ref5.linksUnderline;
  return linksUnderline ? 'underline' : 'none';
});