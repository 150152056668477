import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { shape, string } from 'prop-types';
import { Flex, Chip } from '@magalu/stereo-ui/atoms';
import { Icon } from '@magalu/stereo-ui-icons';
import { getCurrentPath, routePush, routeReplace, urlCleanSelectedFilters } from '@magalu/mixer-utils';
import ButtonAction from '../ButtonAction';
import DialogReview from '../DialogReview';
var SortSelect = function SortSelect(_ref) {
  var _structure$route;
  var structure = _ref.structure;
  var query = structure.query,
    config = structure.config;
  var _ref2 = config || {},
    isMiniApp = _ref2.isMiniApp;
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    showFloatModal = _React$useState2[0],
    setShowFloatModal = _React$useState2[1];
  var selectedOption = (_structure$route = structure.route) == null ? void 0 : _structure$route.sortType;
  var _React$useState3 = React.useState(selectedOption),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    selectedSort = _React$useState4[0],
    setSelectedSort = _React$useState4[1];
  var sortTypes = [{
    type: 'Mais recentes',
    value: 'MOST_RECENT'
  }, {
    type: 'Mais relevantes',
    value: 'MORE_RELEVANT'
  }, {
    type: 'Avaliações positivas',
    value: 'MORE_POSITIVE'
  }, {
    type: 'Avaliações negativas',
    value: 'MORE_NEGATIVE'
  }];
  var handleRouteChange = function handleRouteChange(path) {
    return isMiniApp ? routeReplace({
      path: path
    }) : routePush({
      path: path,
      spa: true
    });
  };
  var onSubmitSort = function onSubmitSort(e) {
    e.preventDefault();
    var sort = sortTypes.some(function (sortType) {
      return sortType.value === selectedSort;
    });
    if (sort) {
      var path = getCurrentPath({
        page: 1,
        sortType: selectedSort
      });
      handleRouteChange(path);
    }
    setShowFloatModal(false);
  };
  var handleClearSort = function handleClearSort(e) {
    e.preventDefault();
    setSelectedSort('');
    if (query == null ? void 0 : query.sortType) {
      delete query.sortType;
      var path = urlCleanSelectedFilters([], query, structure);
      handleRouteChange(path);
    }
    setShowFloatModal(false);
  };
  return React.createElement(Flex, null, React.createElement(ButtonAction, {
    variation: "outline",
    label: "Ordenar",
    endIcon: React.createElement(Icon, {
      name: "ChevronDown"
    }),
    onClick: function onClick() {
      return setShowFloatModal(true);
    }
  }), React.createElement(DialogReview, {
    open: showFloatModal,
    title: "Coment\xE1rios dos clientes",
    contentText: "Ordenar:",
    handleSubmit: onSubmitSort,
    handleClear: handleClearSort,
    disabledButton: !selectedSort,
    handleCloseModal: function handleCloseModal() {
      return setShowFloatModal(false);
    }
  }, sortTypes.map(function (item) {
    return React.createElement(Chip, {
      as: "a",
      type: "radio",
      onClick: function onClick() {
        return setSelectedSort(item.value);
      },
      checked: selectedSort === item.value,
      value: item.value,
      key: item.value,
      id: item.value,
      autoSize: true,
      m: 2,
      fontSize: "14px",
      lineHeight: "16px",
      bg: "whisper",
      scales: {
        dark: "#343B3F",
        light: "#fff"
      }
    }, item.type);
  })));
};
process.env.NODE_ENV !== "production" ? SortSelect.propTypes = {
  structure: shape({
    route: shape({
      sortType: string
    })
  })
} : void 0;
SortSelect.defaultProps = {
  structure: {
    route: {}
  }
};
SortSelect.ssr = true;
export default SortSelect;