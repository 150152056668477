import React from 'react';
import { Box, Text, Chip } from '@magalu/stereo-ui/atoms';
import { Icon, Star } from '@magalu/stereo-ui-icons';
import { shape } from 'prop-types';
import { routePush, urlCleanSelectedFilters } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { TextChip } from './ReviewSelectedFilter.styles';
var ReviewSelectedFilter = function ReviewSelectedFilter(_ref) {
  var structure = _ref.structure;
  var _ref2 = (structure == null ? void 0 : structure.route) || {},
    _ref2$filters = _ref2.filters,
    filters = _ref2$filters === void 0 ? [] : _ref2$filters;
  var query = structure.query;
  var rating = filters.find(function (item) {
    return item.type === 'rating';
  });
  function getRatingLabel() {
    var ratingLabels = {
      FIVE_STARS: '5',
      FOUR_STARS: '4',
      ONE_STAR: '1',
      THREE_STARS: '3',
      TWO_STARS: '2'
    };
    if (rating) {
      return ratingLabels[rating.value];
    }
    return null;
  }
  var ratingLabel = getRatingLabel();
  var handleClearFilterRating = function handleClearFilterRating(e) {
    e.preventDefault();
    var filterRating = filters.filter(function (item) {
      return item.type === 'rating';
    }).map(function (item) {
      return {
        type: item.type,
        value: item.value
      };
    });
    var path = urlCleanSelectedFilters(filterRating, query, structure);
    routePush({
      path: path,
      spa: true
    });
  };
  var renderTextChip = function renderTextChip() {
    return React.createElement(TextChip, null, ratingLabel, React.createElement(Box, {
      p: "0 4px"
    }, React.createElement(Star, {
      "data-testid": "star-icon",
      color: "text.scratched",
      height: 16,
      width: 16
    })));
  };
  if (rating) {
    return React.createElement(Box, {
      bg: "background.white",
      "data-testid": "filter-selected-container"
    }, React.createElement(Text, {
      pb: 3,
      fontWeight: "medium",
      fontSize: 2
    }, "Filtros Selecionados"), React.createElement(Chip, {
      clickable: true,
      width: "72px",
      autoSize: true,
      bg: "secondary",
      as: "div",
      color: "base",
      padding: "8px",
      fontSize: "14px",
      lineHeight: "16px",
      endIcon: React.createElement(Icon, {
        name: "Close",
        width: "16px",
        height: "16px",
        color: "#697277",
        "data-testid": "clear-filter",
        onClick: handleClearFilterRating
      }),
      key: ratingLabel,
      id: "chip-selected-filter"
    }, renderTextChip()));
  }
  return null;
};
process.env.NODE_ENV !== "production" ? ReviewSelectedFilter.propTypes = {
  structure: shape({})
} : void 0;
ReviewSelectedFilter.defaultProps = {
  structure: {
    route: {}
  }
};
ReviewSelectedFilter.ssr = true;
export default withLayoutProps(ReviewSelectedFilter);