import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useEffect, useState } from 'react';
import { bool, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Text, Button } from '@magalu/stereo-ui/atoms';
import { usePublisher } from '@magalu/mixer-publisher';
import { routePush, useCloseMiniApp } from '@magalu/mixer-utils';
import { DialogContainer as Dialog, ButtonContainer } from './ReviewModal.styles';
var ReviewModal = function ReviewModal(_ref) {
  var _variant$createUserRe, _variant$createUserRe2, _variant$createUserRe3, _variant$createUserRe4, _variant$createUserRe5;
  var modalOpen = _ref.open,
    variant = _ref.variant,
    data = _ref.data,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var channel = staticProps.channel;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish,
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _useState = useState(modalOpen),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var product = data.product;
  var basePath = structure.basePath;
  var handleClickEvent = useCallback(function () {
    setOpen(!open);
  }, [open]);
  useEffect(function () {
    subscribe('review:modal:open', handleClickEvent);
    return function () {
      unsubscribe('review:modal:open', handleClickEvent);
    };
  }, []);
  var handleClickConditional = function handleClickConditional() {
    publish('product:reviewform:modal', {
      structure: structure
    });
    if (channel === 'miniapp') {
      return useCloseMiniApp();
    }
    return routePush({
      path: "".concat(basePath, "/").concat(product.url),
      spa: true
    });
  };
  var variants = {
    ERROR: {
      buttonLabel: 'Retornar à avaliação',
      description: (variant == null ? void 0 : (_variant$createUserRe = variant.createUserReviewSubmission) == null ? void 0 : _variant$createUserRe.description) || 'Não conseguimos enviar a sua avaliação. Por favor, faça novamente.',
      handleClick: handleClickEvent,
      icon: 'CloseCircle',
      iconColor: 'alert.danger.text',
      textColor: 'text.dark',
      title: variant == null ? void 0 : (_variant$createUserRe2 = variant.createUserReviewSubmission) == null ? void 0 : _variant$createUserRe2.msg
    },
    SUCCESS: {
      buttonLabel: 'Voltar para o produto',
      description: (variant == null ? void 0 : (_variant$createUserRe3 = variant.createUserReviewSubmission) == null ? void 0 : _variant$createUserRe3.description) || 'Após a análise, sua avaliação será publicada.',
      handleClick: handleClickConditional,
      icon: 'DoneCircle',
      iconColor: 'alert.success.text',
      textColor: 'text.attributeLabel',
      title: "".concat(variant == null ? void 0 : (_variant$createUserRe4 = variant.createUserReviewSubmission) == null ? void 0 : _variant$createUserRe4.msg)
    }
  };
  var currentVariant = variants[(variant == null ? void 0 : (_variant$createUserRe5 = variant.createUserReviewSubmission) == null ? void 0 : _variant$createUserRe5.status) || 'SUCCESS'];
  return React.createElement(React.Fragment, null, open && React.createElement(Dialog, {
    type: "alert",
    onClickOutside: handleClickEvent,
    position: "center",
    showClose: true,
    visible: open
  }, React.createElement(Icon, {
    name: currentVariant.icon,
    color: currentVariant.iconColor,
    "aria-label": currentVariant.icon,
    m: "0 auto",
    minWidth: 48,
    minHeight: 48,
    display: "block"
  }), React.createElement(Text, {
    color: currentVariant.textColor,
    fontSize: [2, 3],
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "display",
    mt: ['32px', '40px']
  }, currentVariant.title), React.createElement(Text, {
    mt: ['8px', '24px'],
    color: "text.attributeLabel",
    fontSize: [2, 3],
    fontWeight: "regular",
    textAlign: "center",
    lineHeight: "display"
  }, currentVariant.description), React.createElement(ButtonContainer, null, React.createElement(Button, {
    uppercase: false,
    size: "large",
    width: ['264px', '304px'],
    "data-testid": "button-review-modal",
    onClick: currentVariant.handleClick,
    type: "button"
  }, currentVariant.buttonLabel))));
};
process.env.NODE_ENV !== "production" ? ReviewModal.propTypes = {
  data: shape({
    product: shape({})
  }),
  open: bool,
  "static": shape({}),
  structure: shape({}),
  variant: string
} : void 0;
ReviewModal.defaultProps = {
  data: {},
  open: false,
  "static": {},
  structure: {},
  variant: 'success'
};
ReviewModal.ssr = false;
export default ReviewModal;