import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { Flex, Button, Text } from '@magalu/stereo-ui/atoms';
import { BoxContainer, ButtonClear, ButtonContainer, Container, DialogContainer as Dialog } from './DialogReview.styles';
var DialogReview = function DialogReview(_ref) {
  var open = _ref.open,
    children = _ref.children,
    title = _ref.title,
    contentText = _ref.contentText,
    handleCloseModal = _ref.handleCloseModal,
    handleSubmit = _ref.handleSubmit,
    handleClear = _ref.handleClear,
    disabledButton = _ref.disabledButton;
  return React.createElement(React.Fragment, null, open && React.createElement(Dialog, {
    title: ('title', title),
    titleStyle: {
      color: 'base',
      fontSize: '20px',
      lineHeight: '25px'
    },
    showClose: true,
    showHorizontalRule: true,
    bgRule: "border.base",
    onClickOutside: handleCloseModal
  }, React.createElement(Flex, {
    flexDirection: "column",
    p: 2
  }, React.createElement(Text, {
    pl: "24px",
    fontSize: 2,
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "roboto",
    "data-testid": "title-filter"
  }, contentText), React.createElement(BoxContainer, null, children)), React.createElement(Container, null, React.createElement(ButtonContainer, null, React.createElement(ButtonClear, {
    disabled: disabledButton,
    onClick: handleClear,
    "data-testid": "sort-clear",
    color: "dark"
  }, "Limpar"), React.createElement(Button, {
    onClick: handleSubmit,
    "data-testid": "sort-submit"
  }, "Aplicar")))));
};
process.env.NODE_ENV !== "production" ? DialogReview.propTypes = {
  children: node,
  contentText: string,
  disabledButton: bool,
  handleClear: func,
  handleCloseModal: func,
  handleSubmit: func,
  open: bool,
  title: string
} : void 0;
DialogReview.defaultProps = {
  children: null,
  contentText: '',
  disabledButton: true,
  handleClear: function handleClear() {},
  handleCloseModal: function handleCloseModal() {},
  handleSubmit: function handleSubmit() {},
  open: false,
  title: ''
};
export default DialogReview;