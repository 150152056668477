import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["sortLabel"];
import React from 'react';
import { shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import withLayoutProps from '../../../hocs/withLayoutProps';
import SortSelect from './SortSelect';
import { TotalLabel } from './ReviewListHeader.styles';
var ReviewListHeader = function ReviewListHeader(_ref) {
  var _data$productRating, _data$productRating$g;
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var sortLabel = staticProps.sortLabel,
    props = _objectWithoutProperties(staticProps, _excluded);
  var total = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$g = _data$productRating.general) == null ? void 0 : _data$productRating$g.commentCount) || 0;
  var sortProps = {
    selectLabel: sortLabel
  };
  return React.createElement(Flex, _extends({
    justifyContent: "space-between",
    alignItems: "center",
    "data-testid": "review-list-header"
  }, props), total > 0 && React.createElement(TotalLabel, {
    "data-testid": "review-total",
    fontSize: 3
  }, total, "\xA0", total === 1 ? 'comentário' : 'comentários'), React.createElement(SortSelect, {
    "static": sortProps,
    structure: structure
  }));
};
process.env.NODE_ENV !== "production" ? ReviewListHeader.propTypes = {
  data: shape({}),
  "static": shape({
    sortLabel: string
  }),
  structure: shape({
    route: shape({
      sortType: string
    })
  })
} : void 0;
ReviewListHeader.defaultProps = {
  data: {},
  "static": {
    sortLabel: 'Ordenar'
  },
  structure: {
    route: {}
  }
};
ReviewListHeader.ssr = true;
export default withLayoutProps(ReviewListHeader);