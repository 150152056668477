import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useEffect, useState } from 'react';
import { bool, shape, string } from 'prop-types';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { usePublisher } from '@magalu/mixer-publisher';
var ReviewTermsModal = function ReviewTermsModal(_ref) {
  var modalOpen = _ref.open,
    staticProps = _ref["static"];
  var title = staticProps.title,
    termsUrl = staticProps.termsUrl;
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _useState = useState(modalOpen),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var handleClickEvent = useCallback(function () {
    setOpen(!open);
  }, [open]);
  useEffect(function () {
    subscribe('review:terms:open', handleClickEvent);
    return function () {
      unsubscribe('review:terms:open', handleClickEvent);
    };
  }, []);
  return React.createElement(React.Fragment, null, open && React.createElement(Dialog, {
    onClickOutside: handleClickEvent,
    position: "center",
    showClose: true,
    title: title
  }, React.createElement("iframe", {
    frameBorder: 0,
    height: "400px",
    src: termsUrl,
    title: title,
    width: "100%"
  })));
};
process.env.NODE_ENV !== "production" ? ReviewTermsModal.propTypes = {
  open: bool,
  "static": shape({
    termsUrl: string,
    title: string
  })
} : void 0;
ReviewTermsModal.defaultProps = {
  open: false,
  "static": {
    termsUrl: 'https://conteudo.magazineluiza.com.br/regulamentos/termos_e_condicoes_review.html',
    title: 'Termos e Condições'
  }
};
ReviewTermsModal.ssr = false;
ReviewTermsModal.displayName = 'ReviewTermsModal';
export default ReviewTermsModal;