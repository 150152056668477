import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import { Box, Dialog, Button } from '@magalu/stereo-ui/atoms';
import { getTextColor, getBackgroundColor, themeGet } from '@magalu/stereo-ui-styles';
export var BoxContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  padding: ", "px;\n"])), themeGet('space.3'));
export var ButtonClear = styled(Button)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  label {\n    color: ", ";\n  }\n"])), function (props) {
  return props.disabled ? '' : getTextColor('dark');
});
export var ButtonContainer = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 48px;\n  align-items: center;\n  justify-content: space-around;\n  display: flex;\n  flex-direction: row;\n"])));
export var Container = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  background-color: ", ";\n  width: 100%;\n  height: 80px;\n  padding: 16px 20px 16px 20px;\n  justify-content: center;\n  align-items: center;\n"])), getBackgroundColor('white'));
export var DialogContainer = styled(Dialog)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100% !important;\n  margin: 0px;\n  padding: 0px;\n  height: 360px;\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  overflow-x: hidden !important;\n  border-top-right-radius: 0px !important;\n  border-top-left-radius: 0px !important;\n  > div:first-child {\n    padding: 12px 8px 12px 16px;\n  }\n"])));