import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import { StarGroup } from '@magalu/stereo-ui-icons';
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    px: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Description = styled(Box).attrs(function (props) {
  return _extends({
    color: 'text.base',
    fontWeight: 'regular',
    lineHeight: 'big',
    textAlign: 'justify'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var StyledStarGroup = styled(StarGroup)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-right: 8px;\n"])));
export var WarningGenAI = {
  alignItems: 'center',
  color: '#69727763',
  display: 'flex',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '18px',
  marginBottom: '32px',
  marginTop: '32px'
};