import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string } from 'prop-types';
import Button from '@magalu/stereo-ui/atoms/Button';
import { routePush, routeReplace } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useReviewPagination from './useReviewPagination';
var ReviewPaginationButton = function ReviewPaginationButton(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var config = structure.config;
  var _ref2 = config || {},
    isMiniApp = _ref2.isMiniApp;
  var label = staticProps.label;
  var _useReviewPagination = useReviewPagination(data, structure),
    nextPagePath = _useReviewPagination.nextPagePath,
    paginateButton = _useReviewPagination.paginateButton;
  var handleClickPaginate = function handleClickPaginate(e) {
    e.preventDefault();
    var redirectOptions = {
      options: {
        scroll: false
      },
      path: nextPagePath
    };
    if (isMiniApp) routeReplace(redirectOptions);else routePush(_extends({}, redirectOptions, {
      spa: true
    }));
  };
  return paginateButton && React.createElement(Button, {
    as: "a",
    href: nextPagePath,
    variation: "outline",
    uppercase: false,
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    size: "medium",
    full: true,
    borderRadius: 6,
    "data-testid": "pagination-button",
    onClick: handleClickPaginate
  }, label);
};
ReviewPaginationButton.defaultProps = {
  data: {},
  "static": {
    label: ''
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? ReviewPaginationButton.propTypes = {
  data: shape({}),
  "static": shape({
    link: string
  }),
  structure: shape({})
} : void 0;
ReviewPaginationButton.ssr = true;
export default withLayoutProps(ReviewPaginationButton);