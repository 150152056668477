import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Button from '@magalu/stereo-ui/atoms/Button';
import Text from '@magalu/stereo-ui/atoms/Text';
import ReviewInput from '@magalu/stereo-ui/molecules/ReviewInput';
export var ButtonSubmit = styled(Button).attrs(function (props) {
  return _extends({
    color: 'secondary',
    size: 'small'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    px: 1,
    py: 4
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var Description = styled(Box).attrs(function (props) {
  return _extends({
    color: 'text.scratched',
    fontSize: [2, 2, 2, 2],
    fontWeight: 'regular',
    lineHeight: 'big',
    textAlign: 'justify'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var HeadingLabel = styled(Heading).attrs(function (props) {
  return _extends({
    color: 'base',
    fontSize: 2,
    fontWeight: 'bold',
    lineHeight: 'display',
    my: [2, 2, 3, 3]
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var OptionsLabel = styled(Text).attrs(function (props) {
  return _extends({
    color: 'base',
    fontSize: [1, 1, 2, 2],
    fontWeight: 'bold',
    paddingY: '20px'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var ReviewArea = styled(ReviewInput).attrs(function (props) {
  return _extends({
    flexDirection: ['column'],
    gap: '30px',
    p: '20px',
    textAlign: ['left']
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));