import React from 'react';
import { shape } from 'prop-types';
import { Flex } from '@magalu/stereo-ui/atoms';
import withLayoutProps from '../../../hocs/withLayoutProps';
import SortSelect from './SortSelect';
import SelectByRating from './SelectByRating';
var ReviewHeaderMobile = function ReviewHeaderMobile(_ref) {
  var data = _ref.data,
    structure = _ref.structure;
  return React.createElement(Flex, {
    padding: "8px 16px",
    gap: 50,
    "data-testid": "review-list-header-mobile"
  }, React.createElement(SortSelect, {
    structure: structure
  }), React.createElement(SelectByRating, {
    data: data,
    structure: structure
  }));
};
process.env.NODE_ENV !== "production" ? ReviewHeaderMobile.propTypes = {
  data: shape({}),
  structure: shape({})
} : void 0;
ReviewHeaderMobile.defaultProps = {
  data: {},
  structure: {}
};
ReviewHeaderMobile.ssr = true;
export default withLayoutProps(ReviewHeaderMobile);