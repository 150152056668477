import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "endIcon", "startIcon", "variation"];
import React from 'react';
import { string, node, oneOfType } from 'prop-types';
import { Button } from './ButtonAction.styles';
var ButtonAction = function ButtonAction(_ref) {
  var label = _ref.label,
    endIcon = _ref.endIcon,
    startIcon = _ref.startIcon,
    variation = _ref.variation,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Button, _extends({
    endIcon: endIcon,
    startIcon: startIcon,
    uppercase: false,
    variation: variation,
    centered: true,
    "data-testid": "button-action"
  }, props), label);
};
process.env.NODE_ENV !== "production" ? ButtonAction.propTypes = {
  endIcon: oneOfType([node, string]),
  label: string,
  startIcon: oneOfType([node, string]),
  variation: string
} : void 0;
ButtonAction.defaultProps = {
  endIcon: '',
  label: '',
  startIcon: '',
  variation: ''
};
export default ButtonAction;