import React from 'react';
import { shape, number } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import OpinionSnippetBlock from '@magalu/stereo-ui/organisms/OpinionSnippetBlock';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useReviewListing from './useReviewListing';
var ReviewListing = function ReviewListing(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var charLimit = staticProps.charLimit;
  var _useReviewListing = useReviewListing({
      data: data,
      structure: structure
    }),
    reviews = _useReviewListing.reviews,
    getTruncateSize = _useReviewListing.getTruncateSize;
  return React.createElement(Flex, {
    gap: 4,
    flexDirection: "column"
  }, reviews == null ? void 0 : reviews.map(function (item) {
    return React.createElement(OpinionSnippetBlock, {
      key: "review-".concat(item.reviewId),
      review: item,
      charLimit: getTruncateSize(item == null ? void 0 : item.description, charLimit)
    });
  }));
};
ReviewListing.ssr = true;
process.env.NODE_ENV !== "production" ? ReviewListing.propTypes = {
  data: shape({}),
  "static": shape({
    charLimit: number
  }),
  structure: shape({})
} : void 0;
ReviewListing.defaultProps = {
  data: {},
  "static": {
    charLimit: 300
  },
  structure: {}
};
export default withLayoutProps(ReviewListing);