import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect, useRef } from 'react';
import { bool, number, shape, string } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Button from '@magalu/stereo-ui/atoms/Button';
import StereoReviewResult from '@magalu/stereo-ui/templates/ReviewResult';
import { useReview } from '@magalu/mixer-graphql';
import { usePublisher } from '@magalu/mixer-publisher';
import { scrollToRef } from '@magalu/mixer-utils';
import VisibilitySection from '../VisibilitySection';
var ReviewResult = function ReviewResult(_ref) {
  var _sorts$, _sorts$2;
  var staticProps = _ref["static"],
    structure = _ref.structure,
    data = _ref.data;
  var elemRef = useRef(null);
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var reviewPageSize = staticProps.reviewPageSize,
    title = staticProps.title,
    link = staticProps.link,
    linkTitle = staticProps.linkTitle,
    showDesktop = staticProps.showDesktop,
    marginBottomScroll = staticProps.marginBottomScroll;
  var _ref2 = data || {},
    review = _ref2.review,
    product = _ref2.product;
  var _ref3 = review || {},
    _ref3$pagination = _ref3.pagination,
    pagination = _ref3$pagination === void 0 ? {} : _ref3$pagination,
    _ref3$reviews = _ref3.reviews,
    reviews = _ref3$reviews === void 0 ? [] : _ref3$reviews,
    _ref3$sorts = _ref3.sorts,
    sorts = _ref3$sorts === void 0 ? [] : _ref3$sorts;
  var initialVars = {
    page: 1,
    sortOrientation: sorts == null ? void 0 : (_sorts$ = sorts[0]) == null ? void 0 : _sorts$.orientation,
    sortType: sorts == null ? void 0 : (_sorts$2 = sorts[0]) == null ? void 0 : _sorts$2.type
  };
  var _useState = useState(reviews),
    _useState2 = _slicedToArray(_useState, 2),
    list = _useState2[0],
    setList = _useState2[1];
  var _useState3 = useState(initialVars),
    _useState4 = _slicedToArray(_useState3, 2),
    vars = _useState4[0],
    setVars = _useState4[1];
  var _useReview = useReview(),
    reviewData = _useReview.review,
    getReviewData = _useReview.getData;
  var updateData = function updateData() {
    var page = vars.page,
      sortOrientation = vars.sortOrientation,
      sortType = vars.sortType;
    var productId = (product == null ? void 0 : product.variationId) || (product == null ? void 0 : product.id);
    var filters = {
      productId: productId,
      reviewPage: page,
      reviewPageSize: reviewPageSize,
      reviewSortOrientation: sortOrientation,
      reviewSortType: sortType
    };
    getReviewData(filters);
  };
  useEffect(function () {
    setList(reviews);
    setVars(initialVars);
  }, [structure.asPath]);
  useEffect(function () {
    updateData();
  }, [product, vars]);
  useEffect(function () {
    var _reviewData$review;
    var page = vars.page;
    var response = (reviewData == null ? void 0 : (_reviewData$review = reviewData.review) == null ? void 0 : _reviewData$review.reviews) || [];
    if (response.length === 0) return;
    var moreReviews = page > 1 ? [].concat(_toConsumableArray(list), _toConsumableArray(response)) : response;
    setList(moreReviews);
  }, [reviewData]);
  var handleChangeSort = function handleChangeSort(_ref4) {
    var type = _ref4.type,
      orientation = _ref4.orientation;
    setVars({
      page: 1,
      sortOrientation: orientation,
      sortType: type
    });
  };
  var handleViewMoreClick = function handleViewMoreClick() {
    var next = vars.page + 1;
    setVars(_extends({}, vars, {
      page: next
    }));
  };
  var handleTitleClickEvent = function handleTitleClickEvent() {
    return scrollToRef(elemRef, marginBottomScroll);
  };
  useEffect(function () {
    subscribe('product:review:click', handleTitleClickEvent);
    return function () {
      unsubscribe('product:review:click', handleTitleClickEvent);
    };
  });
  return React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:review"
  }, React.createElement(React.Fragment, null, title && React.createElement(Heading, {
    as: "h2",
    color: "base",
    fontSize: 5,
    fontWeight: "medium",
    my: [2, 2, 3, 3],
    ref: elemRef,
    yOffset: marginBottomScroll
  }, title), link && linkTitle && React.createElement(Button, {
    as: "a",
    href: link,
    m: "10px 0 0 auto",
    maxWidth: "315px"
  }, linkTitle), React.createElement(StereoReviewResult, {
    productData: product,
    reviewsData: _extends({}, review, {
      pagination: _extends({}, pagination, {
        page: vars.page
      }),
      reviews: list
    }),
    onViewMoreClick: handleViewMoreClick,
    onChangeSort: handleChangeSort,
    showDesktop: showDesktop
  })));
};
ReviewResult.defaultProps = {
  data: {},
  "static": {
    link: '',
    linkTitle: '',
    reviewPageSize: 20,
    showDesktop: false,
    title: ''
  },
  structure: shape({})
};
process.env.NODE_ENV !== "production" ? ReviewResult.propTypes = {
  data: shape({}),
  "static": shape({
    link: string,
    linkTitle: string,
    reviewPageSize: number,
    showDesktop: bool,
    title: string
  }),
  structure: {}
} : void 0;
ReviewResult.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  review: {
    query: 'ReviewQuery'
  }
};
ReviewResult.ssr = true;
export default ReviewResult;