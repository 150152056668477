import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { getCurrentPath } from '@magalu/mixer-utils';
var getPagePath = function getPagePath(page) {
  return getCurrentPath({
    page: page
  });
};
var useReviewPagination = function useReviewPagination(data, structure) {
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    nextPagePath = _useState2[0],
    setNextPagePath = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    paginateButton = _useState4[0],
    setPaginateButton = _useState4[1];
  var _structure$route = structure == null ? void 0 : structure.route,
    _structure$route$page = _structure$route.page,
    page = _structure$route$page === void 0 ? 1 : _structure$route$page;
  React.useEffect(function () {
    var _data$productRating, _data$productRating$u, _data$productRating$u2;
    var currentPage = Number(page);
    var nextPage = currentPage + 1;
    var totalPages = data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$u = _data$productRating.userReviews) == null ? void 0 : (_data$productRating$u2 = _data$productRating$u.page) == null ? void 0 : _data$productRating$u2.totalPages;
    if (currentPage >= totalPages) return setPaginateButton(false);
    setPaginateButton(true);
    return setNextPagePath(getPagePath(nextPage));
  }, [JSON.stringify(data)]);
  return {
    nextPagePath: nextPagePath,
    paginateButton: paginateButton
  };
};
export default useReviewPagination;