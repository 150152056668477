import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { shape } from 'prop-types';
import { Flex, Chip } from '@magalu/stereo-ui/atoms';
import { Icon, Star } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import { getCurrentPath, routePush, routeReplace, urlCleanSelectedFilters } from '@magalu/mixer-utils';
import ButtonAction from '../ButtonAction';
import DialogReview from '../DialogReview';
import { FilterCount, TextChip } from './SelectRating.styles';
var SelectByRating = function SelectByRating(_ref) {
  var structure = _ref.structure,
    data = _ref.data;
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    showFloatModal = _React$useState2[0],
    setShowFloatModal = _React$useState2[1];
  var _ref2 = data.productRating || {},
    _ref2$reviewsByRating = _ref2.reviewsByRating,
    reviewsByRating = _ref2$reviewsByRating === void 0 ? [] : _ref2$reviewsByRating;
  var _ref3 = (structure == null ? void 0 : structure.route) || {},
    _ref3$filters = _ref3.filters,
    filters = _ref3$filters === void 0 ? [] : _ref3$filters;
  var query = structure.query,
    config = structure.config;
  var _ref4 = config || {},
    isMiniApp = _ref4.isMiniApp;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var reviewsByRatingData = reviewsByRating.map(function (ratingReview) {
    var rating = ratingReview.rating,
      total = ratingReview.total;
    var rate = new Map([[1, 'ONE_STAR'], [2, 'TWO_STARS'], [3, 'THREE_STARS'], [4, 'FOUR_STARS'], [5, 'FIVE_STARS']]);
    return {
      href: "rating---".concat(rate.get(rating)),
      key: rating,
      selected: false,
      total: total,
      type: rate.get(rating),
      value: rating
    };
  });
  var handleRouteChange = function handleRouteChange(path) {
    return isMiniApp ? routeReplace({
      path: path
    }) : routePush({
      path: path,
      spa: true
    });
  };
  var selectedFilter = filters.filter(function (filter) {
    return filter.type === 'rating';
  });
  var updatedReviewsByRatingData = reviewsByRatingData.map(function (item) {
    var _rating$;
    var rating = filters.filter(function (filter) {
      return filter.type === 'rating';
    }).map(function (filter) {
      return {
        type: filter.type,
        value: filter.value
      };
    });
    if (item.type === ((_rating$ = rating[0]) == null ? void 0 : _rating$.value)) {
      return _extends({}, item, {
        selected: true
      });
    }
    return item;
  });
  var _React$useState3 = React.useState(updatedReviewsByRatingData),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    options = _React$useState4[0],
    setOptions = _React$useState4[1];
  var onChange = function onChange(filter) {
    if (filter.total === 0) return;
    var newValue = options.map(function (item) {
      var same = item.value === filter.value;
      return _extends({}, item, {
        selected: !!same
      });
    });
    setOptions(newValue);
  };
  var onSubmitFilterRating = function onSubmitFilterRating(e) {
    e.preventDefault();
    var filterRating = options.find(function (item) {
      return item.selected;
    });
    if (!filterRating) {
      setShowFloatModal(false);
      return;
    }
    var rating = filters.filter(function (item) {
      return (item == null ? void 0 : item.type) === filterRating.type;
    });
    if (rating) {
      var path = getCurrentPath({
        filters: filterRating == null ? void 0 : filterRating.href,
        page: 1
      });
      handleRouteChange(path);
      publish('review:filter:click', filterRating.type, {
        data: data,
        structure: structure
      });
    }
    setShowFloatModal(false);
  };
  var handleClearFilterRating = function handleClearFilterRating(e) {
    e.preventDefault();
    var clearSelect = options.map(function (item) {
      return _extends({}, item, {
        selected: false
      });
    });
    setOptions(clearSelect);
    var rating = filters.filter(function (item) {
      return item.type === 'rating';
    }).map(function (item) {
      return {
        type: item.type,
        value: item.value
      };
    });
    if (query == null ? void 0 : query.filters) {
      var path = urlCleanSelectedFilters(rating, query, structure);
      handleRouteChange(path);
    }
    setShowFloatModal(false);
  };
  var renderTextChip = function renderTextChip(_ref5) {
    var label = _ref5.label,
      total = _ref5.total;
    return React.createElement(TextChip, {
      as: "span"
    }, label, React.createElement(Star, {
      color: "text.scratched",
      height: 16,
      width: 16
    }), "(".concat(total, ")"));
  };
  return React.createElement(Flex, null, React.createElement(ButtonAction, {
    variation: "outline",
    label: "Filtrar",
    startIcon: Boolean(selectedFilter == null ? void 0 : selectedFilter.length) && React.createElement(FilterCount, {
      "data-testid": "filter-count",
      forwardedAs: "span"
    }, selectedFilter == null ? void 0 : selectedFilter.length),
    endIcon: React.createElement(Icon, {
      name: "ChevronDown"
    }),
    onClick: function onClick() {
      return setShowFloatModal(true);
    },
    width: "90px"
  }), React.createElement(DialogReview, {
    open: showFloatModal,
    title: "Coment\xE1rios dos clientes",
    contentText: "Filtrar:",
    handleSubmit: onSubmitFilterRating,
    handleClear: handleClearFilterRating,
    disabledButton: !options.some(function (option) {
      return option.selected;
    }),
    handleCloseModal: function handleCloseModal() {
      return setShowFloatModal(false);
    }
  }, options == null ? void 0 : options.map(function (item) {
    return React.createElement(Chip, {
      as: "a",
      onClick: function onClick() {
        return onChange(item);
      },
      checked: item == null ? void 0 : item.selected,
      value: String(item == null ? void 0 : item.value),
      key: String(item == null ? void 0 : item.key),
      id: String(item == null ? void 0 : item.value),
      type: "radio",
      autoSize: true,
      padding: "8px",
      bg: "whisper",
      fontSize: "14px",
      lineHeight: "16px",
      m: 3,
      scales: {
        dark: "#343B3F",
        light: "#fff"
      }
    }, renderTextChip({
      label: item == null ? void 0 : item.value,
      total: item == null ? void 0 : item.total
    }));
  })));
};
process.env.NODE_ENV !== "production" ? SelectByRating.propTypes = {
  data: shape({}),
  structure: shape({})
} : void 0;
SelectByRating.defaultProps = {
  data: {},
  structure: {}
};
SelectByRating.ssr = true;
export default SelectByRating;