import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Button from '@magalu/stereo-ui/atoms/Button';
import { Chip } from '@magalu/stereo-ui/atoms';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ProductBundleSelector from '../ProductBundleSelector';
import VisibilitySection from '../VisibilitySection';
import RichDescription from '../RichDescription';
import { Content, Description, WarningGenAI, StyledStarGroup } from './ReviewSummary.styles';
var baseTitle = {
  color: 'base',
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'display',
  my: [2, 2, 3, 3]
};
function ReviewSummary(_ref) {
  var data = _ref.data,
    staticProps = _ref.staticProps,
    structure = _ref.structure;
  var product = data.product,
    reviewSummary = data.reviewSummary;
  var _structure$route = structure.route,
    productId = _structure$route.productId,
    slug = _structure$route.slug,
    categoryId = _structure$route.categoryId,
    subCategoryId = _structure$route.subCategoryId;
  if (!(reviewSummary == null ? void 0 : reviewSummary.summary)) {
    return null;
  }
  var tags = (reviewSummary.tags || []).slice(0, 4);
  return React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:detail"
  }, React.createElement(Content, {
    "data-testid": "summary-detail"
  }, staticProps.label && React.createElement(Heading, _extends({}, baseTitle, {
    fontSize: [2, 2, 5, 5],
    fontWeight: "medium",
    "data-testid": "summary-detail-label",
    as: staticProps.labelAs || 'h2'
  }), staticProps.label), React.createElement(ProductBundleSelector, {
    data: product
  }), React.createElement(Flex, {
    justifyContent: "flex-start",
    flexWrap: "wrap",
    mb: 3
  }, tags.map(function (tag) {
    return React.createElement(Chip, {
      key: tag,
      size: "small",
      bg: "primary",
      as: "div",
      color: "white",
      maxWidth: true,
      textMaxWidth: true,
      fontSize: 14,
      autoSize: true,
      borderRadius: 6,
      mr: 3
    }, tag);
  })), React.createElement(Description, {
    fontSize: [1, 1, 2, 2],
    "data-testid": "summary-detail-description"
  }, React.createElement(RichDescription, {
    "static": {
      deniedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'div', 'img', 'svg'],
      richContent: reviewSummary.summary
    }
  })), React.createElement("div", {
    style: _extends({}, WarningGenAI)
  }, React.createElement(StyledStarGroup, null), "Resumo das avalia\xE7\xF5es do produto feito por intelig\xEAncia artificial, com base nas avalia\xE7\xF5es de clientes reais"), React.createElement(Button, {
    as: "a",
    href: "/review/".concat(productId, "/").concat(slug, "/").concat(categoryId, "/").concat(subCategoryId),
    target: "_blank",
    uppercase: false,
    size: "large",
    variation: "outline",
    mb: 4,
    "data-testid": "review-link"
  }, "Ver todas as avalia\xE7\xF5es")));
}
ReviewSummary.ssr = true;
ReviewSummary.defaultProps = {
  staticProps: {
    label: 'O que os clientes estão falando do produto',
    labelAs: 'h2'
  }
};
process.env.NODE_ENV !== "production" ? ReviewSummary.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      brand: PropTypes.shape({
        slug: PropTypes.string.isRequired
      }).isRequired,
      category: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired,
      subcategory: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    reviewSummary: PropTypes.shape({
      summary: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired,
  staticProps: PropTypes.shape({
    label: PropTypes.string,
    labelAs: PropTypes.string
  }),
  structure: PropTypes.shape({
    route: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subCategoryId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
} : void 0;
export default withLayoutProps(ReviewSummary);