import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { themeGet } from '@magalu/stereo-ui-styles';
export var Content = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-top: 34px;\n"])));
export var Divider = styled(Box).attrs(function () {
  return {
    forwardedAs: 'span'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  margin-top: ", "px;\n  width: 100%;\n  border-top: 1px solid rgba(185, 185, 185, 0.73);\n"])), themeGet('space.3'));
export var MainContentReview = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: '94px 16px 69px';\n"])));
export var TotalLabel = styled(Flex)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: #51585c;\n  margin-top: 29px;\n"])));