import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { Text } from '@magalu/stereo-ui/atoms';
import { themeGet } from '@magalu/stereo-ui-styles';
export var FilterCount = styled(Text).attrs({
  bg: 'background.primary',
  borderRadius: 'circle',
  color: 'text.lightest',
  fontSize: 0
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: ", ";\n  line-height: ", "px;\n  display: block;\n  height: 18px;\n  width: 18px;\n"])), themeGet('radii.circle'), themeGet('lineHeights.tall'));
export var TextChip = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 4px;\n  font-weight: 400;\n  color: #000;\n  font-size: ", "px;\n  line-height: 18px;\n\n  &::first-letter {\n    font-weight: 500 !important;\n    color: #000;\n  }\n"])), themeGet('fontSizes.2'));