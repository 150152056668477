import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string } from 'prop-types';
import { getCurrentPath, routePush } from '@magalu/mixer-utils';
import { Select } from './SortSelect.styles';
var sortTypes = [{
  label: 'Mais relevantes',
  value: 'MORE_RELEVANT'
}, {
  label: 'Mais recentes',
  value: 'MOST_RECENT'
}, {
  label: 'Avaliações positivas',
  value: 'MORE_POSITIVE'
}, {
  label: 'Avaliações negativas',
  value: 'MORE_NEGATIVE'
}];
var SortSelect = function SortSelect(_ref) {
  var _structure$route;
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var _ref2 = staticProps || {},
    selectLabel = _ref2.selectLabel;
  var selectedOption = (_structure$route = structure.route) == null ? void 0 : _structure$route.sortType;
  var onChange = function onChange(label) {
    var sort = sortTypes.some(function (sortType) {
      return sortType.value === label;
    });
    if (sort) {
      var path = getCurrentPath({
        page: 1,
        sortType: label
      });
      routePush({
        path: path,
        spa: true
      });
    }
  };
  var options = function options() {
    return sortTypes.map(function (sortType) {
      if (sortType.value === selectedOption) {
        return _extends({}, sortType, {
          selected: true
        });
      }
      return sortType;
    });
  };
  return React.createElement(Select, {
    id: "sort-review",
    desktopMode: true,
    textLabel: selectLabel,
    options: options(),
    onChange: onChange
  });
};
process.env.NODE_ENV !== "production" ? SortSelect.propTypes = {
  "static": shape({
    selectLabel: string
  }),
  structure: shape({
    route: shape({
      sortType: string
    })
  })
} : void 0;
SortSelect.defaultProps = {
  "static": {
    selectLabel: 'Ordenar'
  },
  structure: {
    route: {}
  }
};
SortSelect.ssr = true;
export default SortSelect;