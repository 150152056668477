import React from 'react';
import { shape, string } from 'prop-types';
import Review from '@magalu/stereo-ui/molecules/Review';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import List from '@magalu/stereo-ui/molecules/List';
import withLayoutProps from '../../../hocs/withLayoutProps';
var ReviewDimensions = function ReviewDimensions(_ref) {
  var _data$productRating;
  var staticProps = _ref["static"],
    data = _ref.data;
  var title = staticProps.title;
  var dimensions = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : _data$productRating.dimensions) || [];
  return !!(dimensions == null ? void 0 : dimensions.length) && React.createElement(Box, {
    "data-testid": "review-by-dimension",
    mt: [0, 4],
    mb: [0, 4]
  }, title && React.createElement(Text, {
    fontWeight: "medium"
  }, title), React.createElement(List, {
    disableGutters: true,
    scale: "small",
    dataSource: dimensions,
    renderItem: function renderItem(dimension) {
      var label = dimension.label,
        rating = dimension.rating;
      return React.createElement(Flex, {
        "data-testid": "dimension-rating",
        mt: 3,
        justifyContent: "space-between"
      }, React.createElement(Text, {
        lineHeight: 1.2,
        fontSize: 2
      }, label), React.createElement(Flex, {
        gap: 2
      }, React.createElement(Review, {
        score: rating,
        width: [15, 20],
        height: [15, 20],
        mr: 1
      }), React.createElement(Text, {
        fontSize: 2
      }, rating.toFixed(1))));
    }
  }));
};
ReviewDimensions.ssr = true;
process.env.NODE_ENV !== "production" ? ReviewDimensions.propTypes = {
  data: shape({}),
  "static": shape({
    title: string
  })
} : void 0;
ReviewDimensions.defaultProps = {
  data: {},
  "static": {
    title: ''
  }
};
export default withLayoutProps(ReviewDimensions);