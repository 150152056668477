import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["allowedTags", "deniedTags", "richContent", "settings"];
import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { sanitizeHtml } from '@magalu/mixer-utils';
import { Container } from './RichDescription.styles';
var RichDescription = function RichDescription(_ref) {
  var _ref$static = _ref["static"];
  _ref$static = _ref$static === void 0 ? {} : _ref$static;
  var _ref$static$allowedTa = _ref$static.allowedTags,
    allowedTags = _ref$static$allowedTa === void 0 ? [] : _ref$static$allowedTa,
    _ref$static$deniedTag = _ref$static.deniedTags,
    deniedTags = _ref$static$deniedTag === void 0 ? [] : _ref$static$deniedTag,
    richContent = _ref$static.richContent,
    _ref$static$settings = _ref$static.settings,
    settings = _ref$static$settings === void 0 ? {} : _ref$static$settings,
    props = _objectWithoutProperties(_ref$static, _excluded);
  var image = settings.image;

  return Boolean(richContent) && React.createElement(Container, _extends({
    dangerouslySetInnerHTML: {
      __html: sanitizeHtml(richContent, {
        allowedTags: allowedTags,
        deniedTags: deniedTags
      })
    },
    image: image,
    "data-testid": "rich-content-container"
  }, props));
};
process.env.NODE_ENV !== "production" ? RichDescription.propTypes = {
  "static": shape({
    allowedTags: arrayOf(string),
    deniedTags: arrayOf(string),
    linksUnderline: bool,
    richContent: string.isRequired,
    settings: shape({
      image: shape({
        sizes: arrayOf(number)
      })
    })
  })
} : void 0;
RichDescription.defaultProps = {
  "static": {}
};
RichDescription.ssr = true;
export default RichDescription;