import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
var TextAnchor = styled(Text).attrs({
  role: 'contentinfo'
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n  text-decoration: underline;\n"])));
var AgreedLabel = function AgreedLabel(_ref) {
  var onClick = _ref.onClick;
  return React.createElement(Text, {
    as: "span",
    display: "flex",
    width: 1,
    gap: [0, 1],
    flexDirection: ['column', 'row'],
    fontSize: [1, 2]
  }, "Concordo com os", React.createElement(TextAnchor, {
    "data-testid": "review-terms",
    onClick: onClick
  }, "termos e condi\xE7\xF5es"));
};
process.env.NODE_ENV !== "production" ? AgreedLabel.propTypes = {
  onClick: func.isRequired
} : void 0;
export default AgreedLabel;