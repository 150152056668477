import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
var ReviewTotalizers = function ReviewTotalizers(_ref) {
  var productRating = _ref.data.productRating,
    showComment = _ref.showComment,
    staticProps = _ref["static"],
    flexDirection = _ref.flexDirection;
  var _ref2 = productRating || {},
    _ref2$general = _ref2.general;
  _ref2$general = _ref2$general === void 0 ? {} : _ref2$general;
  var _ref2$general$rating = _ref2$general.rating,
    rating = _ref2$general$rating === void 0 ? 0 : _ref2$general$rating,
    _ref2$general$reviewC = _ref2$general.reviewCount,
    reviewCount = _ref2$general$reviewC === void 0 ? 0 : _ref2$general$reviewC,
    _ref2$general$comment = _ref2$general.commentCount,
    commentCount = _ref2$general$comment === void 0 ? 0 : _ref2$general$comment;
  return React.createElement(Flex, _extends({
    justifyContent: "space-between",
    width: "100%",
    "data-testid": "review-totalizers",
    gap: 2,
    flexDirection: flexDirection
  }, staticProps), React.createElement(Flex, {
    gap: 2
  }, React.createElement(Text, {
    lineHeight: 1,
    fontSize: 44,
    fontWeight: "bold",
    as: "span",
    "data-testid": "review-totalizers-rating"
  }, rating == null ? void 0 : rating.toFixed(1)), React.createElement(Icon, {
    name: "Star",
    color: "stars.base",
    width: 40,
    height: 40
  })), reviewCount > 0 && React.createElement(Text, {
    lineHeight: "24px",
    fontSize: 3,
    mt: "auto",
    mr: 2,
    "data-testid": "review-totalizers-count"
  }, reviewCount, "\xA0", reviewCount !== 1 ? 'avaliações' : 'avaliação'), commentCount > 0 && showComment && React.createElement(Text, {
    lineHeight: "24px",
    fontSize: 3,
    mt: "auto",
    "data-testid": "review-comment-count"
  }, commentCount, "\xA0", commentCount !== 1 ? 'comentários' : 'comentário'));
};
process.env.NODE_ENV !== "production" ? ReviewTotalizers.propTypes = {
  data: shape({
    productRating: shape({
      general: shape({
        rating: number,
        reviewCount: number
      })
    })
  }),
  flexDirection: string,
  showComment: bool,
  "static": shape({})
} : void 0;
ReviewTotalizers.defaultProps = {
  data: {},
  flexDirection: 'row',
  showComment: false,
  "static": {}
};
export default withLayoutProps(ReviewTotalizers);